<template>
  <div>
    <div class="text-h6 mb-3">{{ $t("labels.select_employee") }}</div>
    <v-row>
      <v-col cols="12" sm="6" md="3" class="font-weight-bold">
        <v-checkbox
          dense
          hide-details
          v-model="allSelected"
          :label="$t('labels.all')"
          @change="allToggle"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="employee in employees"
        :key="`emp_${employee.id}`"
      >
        <v-checkbox
          dense
          hide-details
          v-model="selected"
          :label="employee.full_name"
          :value="employee.id"
          multiple
          @change="itemToggle(employee.id)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-btn color="info" small @click="printStampTest()">{{
        $t("labels.print_test")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  downloadPdfFile,
  downloadExcelFile,
  downloadPdfServerFilename,
  isMobile,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
export default {
  name: "PrintStamp",
  data: () => ({
    employees: [],
    selected: [],
    allSelected: false,
  }),
  mounted() {
    this.getEmployees();
    this.listenSocket();
  },
  methods: {
    downloadPdfFile,
    downloadExcelFile,
    downloadPdfServerFilename,
    isMobile,
    listenSocket() {
      if (!this.isMobile() && this.checkPermission(["download_goods_stamp"])) {
        const myWarehouses = [...window.me.warehouses].map((w) => w.id);
        window.socket.on("goods_url_download_stamp", (data) => {
          console.log("data", data);
          const { url, id_warehouse, id_employee } = data;
          if (
            myWarehouses.includes(id_warehouse) &&
            this.selected.includes(id_employee)
          ) {
            // this.downloadPdfFile(url)
            const params = (data.params && JSON.parse(data.params)) || {};
            this.downloadExcelFile(url, params, params.file_name);
          }
        });
      }
    },
    async getEmployees() {
      const wh = localStorage.getItem("_wh") || 0;
      const { data } = await httpClient.post(
        "/employee/v1/get-by-my-warehouse",
        {
          id_warehouse: +wh,
          status: 10,
        }
      );
      this.employees = [...data];
      // this.allToggle()
    },
    allToggle() {
      if (!this.allSelected) {
        this.selected = [];
      } else {
        this.selected = this.employees.map((o) => o.id);
      }
    },
    itemToggle(val) {
      if (!this.selected.includes(val)) {
        this.allSelected = false;
      }
    },

    async printStampTest() {
      await this.downloadPdfServerFilename("/common/v1/generate-goods-stamp", {
        id_goods: 688915,
        insert_tracking: "P7VXAGBP",
        client_time: moment().format("HH:mm DD/MM/YYYY"),
      });
    },
  },
};
</script>

<style scoped></style>
